import React from "react";

import styles from "./styles/Layout.module.scss"
import { Col, Row } from "react-bootstrap";
import ChatThreads from "./ChatThreads";
import Inbox from "./Inbox";


export default function DesktopLayout({ setUser }) {
    return (
        <div className={styles.Layout}>
            <Row className={styles.row}>
                <Col
                    className={styles.col}
                    md={4}
                >
                    <ChatThreads setUser={setUser} />
                </Col>
                <Col
                    className={styles.col}
                    md={8}
                >
                    <Inbox setUser={setUser} />
                </Col>

            </Row>

        </div>
    )
}