import { createSlice } from '@reduxjs/toolkit'

// todo: login
const initialState = {
    isLoggedIn: true,
}

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        updateLogin: (state, actions) => {
            return {
                ...state,
                isLoggedIn: actions.payload
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateLogin } = defaultSlice.actions

export default defaultSlice.reducer