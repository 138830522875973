import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { WEB_ROUTES } from "./constants";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";

import Account from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
import Profile from "./Pages/Profile/Profile";
import Instructions from "./Pages/Instructions";
import Messages from "./Pages/Messages";
import Currency from "./Pages/Currency/Currency";
import PaymentMethod from "./Pages/PaymentMethod/PaymentMethod";
import { uniqueId } from "lodash";

function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ACCOUNT)}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.DASHBOARD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isNavBar={true} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.INSTRUCTIONS)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              selectedRoute={1}
              isNavBar={true}
              isSettingButton={false}
              isSearchBar={false}
            >
              <Instructions />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.PAYMENT_METHOD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              selectedRoute={2}
              isNavBar={true}
              isSettingButton={false}
              isSearchBar={false}
            >
              <PaymentMethod />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.CURRENCY)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              selectedRoute={3}
              isNavBar={true}
              isSettingButton={false}
              isSearchBar={false}
            >
              <Currency />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.CUSTOMER_SUPPORT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              selectedRoute={4}
              isNavBar={true}
              isSettingButton={false}
              isSearchBar={false}
            >
              <Messages />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.PROFILE)}
        element={
          <ProtectedRoute>
            <ProtectedLayout
              selectedRoute={5}
              isNavBar={true}
              isSettingButton={false}
              isSearchBar={false}
            >
              <Profile />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route
          path={baseRoute("*")}
          element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
