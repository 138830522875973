export const WEB_ROUTES = {
    ACCOUNT: 'account',
    DASHBOARD: 'games',
    INSTRUCTIONS: 'instructions',
    PAYMENT_METHOD: 'payment-methods',
    CURRENCY: 'currency',
    CUSTOMER_SUPPORT: 'customer-support',
    PROFILE: 'profile'
}

export const BREAKPOINTS = {
    MOBILE: 991
}
