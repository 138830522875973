import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";
import {
  getRefreshToken,
  getToken,
  getTokenForSession,
  removeToken,
  storeRefreshToken,
  storeToken,
} from "../../Data/services/localStorageService";
import { useCheckRefreshTokenMutation } from "../../Data/services/userAuthApi";

function ProtectedRoute({ children }) {
  var isLoggedIn = getToken();
  var refresh_token = getRefreshToken();
  const navigate = useNavigate();

  // console.log(refresh_token);

  const [checkRefreshToken, { isLoading, isSuccess, isError }] =
    useCheckRefreshTokenMutation();

  useEffect(() => {
    var data = { refresh_token: refresh_token };
    if (!getTokenForSession()) {
      handleRefreshResponse(data);
    }
  }, []);

  const handleRefreshResponse = async (data) => {
    try {
      const response = await checkRefreshToken(data);
      // console.log("Token check response:", response);
      const token = response.data.data.token[0].api_token;
      const refreshToken = response.data.data.refreshToken;
      storeToken(token);
      storeRefreshToken(refreshToken);
    } catch (error) {
      console.error("Error checking token:", error);
      removeToken();
      navigate(baseRoute(WEB_ROUTES.ACCOUNT));
    }
  };

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} replace />
  );
}

export default ProtectedRoute;
