import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Description, SubTitle } from "../../Components/CustomComponents";
import ModalContainer from "../../Components/ModalContainer";
import styles from "./styles/index.module.css";
// unity games integration
import { Unity, useUnityContext } from "react-unity-webgl";

const Dashboard = () => {
  // MARK: MODAL STATE
  const [blackjackModal, setBlackjackModal] = useState(false);
  const [baccaratModal, setBaccaratModal] = useState(false);
  const [prizePoolModal, setPrizePoolModal] = useState(false);
  const [digitDreamsModal, setDigitDreamsModal] = useState(false);
  const [slotMachineModal, setSlotMachineModal] = useState(false);

  const [orientation, setOrientation] = useState(
    window.matchMedia("(orientation: portrait)").matches
      ? "portrait"
      : "landscape"
  );
  useEffect(() => {
    const updateOrientation = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setOrientation("portrait");
      } else if (window.matchMedia("(orientation: landscape)").matches) {
        setOrientation("landscape");
      }
    };

    window.addEventListener("resize", updateOrientation);

    return () => {
      window.removeEventListener("resize", updateOrientation);
    };
  }, []);

  const openGame = (index) => {
    // console.log(index);
    if (index === 0) {
      handleGameModalOpenBlackjack();
    } else if (index === 1) {
      handleGameModalOpenBaccarat();
    } else if (index === 2) {
      handleGameModalOpenSlotMachine();
    } else if (index === 3) {
      handleGameModalOpenPrizePool();
    } else if (index === 4) {
      handleGameModalOpenDigitDreams();
    } else if (index === 5) {
      // handleGameModalOpenSlotMachine();
    }
  };

  // MARK: OPEN MODALS
  const handleGameModalOpenBlackjack = () => {
    setBlackjackModal(true);
    // requestFullscreen(true);
  };

  const handleGameModalOpenBaccarat = () => {
    setBaccaratModal(true);
    // requestFullscreen(true);
  };

  const handleGameModalOpenPrizePool = () => {
    setPrizePoolModal(true);
    // requestFullscreen(true);
  };

  const handleGameModalOpenDigitDreams = () => {
    setDigitDreamsModal(true);
    // requestFullscreen(true);
  };

  const handleGameModalOpenSlotMachine = () => {
    setSlotMachineModal(true);
    // requestFullscreen(true);
  };

  const gameList = [
    {
      id: 1,
      gameName: "Black Jack",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_1.png",
      unityConfig: {
        loaderUrl: "/BlackJack/Build/WebGL.loader.js",
        dataUrl: "/BlackJack/Build/WebGL.data",
        frameworkUrl: "/BlackJack/Build/WebGL.framework.js",
        codeUrl: "/BlackJack/Build/WebGL.wasm",
      },
      active: true,
    },
    {
      id: 2,
      gameName: "Baccarat",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_2.png",
      unityConfig: {
        loaderUrl: "/Baccarat/Build/WebGL.loader.js",
        dataUrl: "/Baccarat/Build/WebGL.data",
        frameworkUrl: "/Baccarat/Build/WebGL.framework.js",
        codeUrl: "/Baccarat/Build/WebGL.wasm",
      },
      active: true,
    },
    {
      id: 3,
      gameName: "Slot Machine",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_3.png",
      unityConfig: {
        loaderUrl: "/Slotmachine/Build/WebGL.loader.js",
        dataUrl: "/Slotmachine/Build/WebGL.data",
        frameworkUrl: "/Slotmachine/Build/WebGL.framework.js",
        codeUrl: "/Slotmachine/Build/WebGL.wasm",
      },
      active: true,
    },
    {
      id: 4,
      gameName: "PRIZE POOL",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_4.png",
      unityConfig: {
        loaderUrl: "/Prizepool/Build/WebGL.loader.js",
        dataUrl: "/Prizepool/Build/WebGL.data",
        frameworkUrl: "/Prizepool/Build/WebGL.framework.js",
        codeUrl: "/Prizepool/Build/WebGL.wasm",
      },
      active: true,
    },
    {
      id: 5,
      gameName: "Didget Dreams",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_5.png",
      unityConfig: {
        loaderUrl: "/Didgetdreams/Build/WebGL.loader.js",
        dataUrl: "/Didgetdreams/Build/WebGL.data",
        frameworkUrl: "/Didgetdreams/Build/WebGL.framework.js",
        codeUrl: "/Didgetdreams/Build/WebGL.wasm",
      },
      active: true,
    },
    {
      id: 6,
      gameName: "blackjack",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_1.png",
      unityConfig: {
        loaderUrl: "/Baccarat/Build/WebGL.loader.js",
        dataUrl: "/Baccarat/Build/WebGL.data",
        frameworkUrl: "/Baccarat/Build/WebGL.framework.js",
        codeUrl: "/Baccarat/Build/WebGL.wasm",
      },
      active: false,
    },
    {
      id: 7,
      gameName: "blackjack",
      gameDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      gameImage: "game_image_1.png",
      unityConfig: {
        loaderUrl: "/Baccarat/Build/WebGL.loader.js",
        dataUrl: "/Baccarat/Build/WebGL.data",
        frameworkUrl: "/Baccarat/Build/WebGL.framework.js",
        codeUrl: "/Baccarat/Build/WebGL.wasm",
      },
      active: false,
    },
  ];

  // MARK: UNITY PROVIDER

  const {
    unityProvider: blackJackUnityProvider,
    isLoaded: blackJackLoaded,
    loadingProgression: blackJackLoadingProgression,
    // unload: blackJackUnload,
    // requestFullscreen: blackJackRequestFullScreen,
    addEventListener: blackJackAddEventListener,
    removeEventListener: blackJackRemoveEventListener,
  } = useUnityContext({
    loaderUrl: "Blackjack/Build/WebGL.loader.js",
    dataUrl: "Blackjack/Build/WebGL.data",
    frameworkUrl: "Blackjack/Build/WebGL.framework.js",
    codeUrl: "Blackjack/Build/WebGL.wasm",
  });

  const {
    unityProvider: baccaratUnityProvider,
    isLoaded: baccaratLoaded,
    loadingProgression: baccaratLoadingProgression,
    // unload: baccaratUnload,
    // requestFullscreen: baccaratRequestFullScreen,
    addEventListener: baccaratAddEventListener,
    removeEventListener: baccaratRemoveEventListener,
  } = useUnityContext({
    loaderUrl: "/Baccarat/Build/WebGL.loader.js",
    dataUrl: "/Baccarat/Build/WebGL.data",
    frameworkUrl: "/Baccarat/Build/WebGL.framework.js",
    codeUrl: "/Baccarat/Build/WebGL.wasm",
  });

  const {
    unityProvider: prizePoolUnityProvider,
    isLoaded: prizePoolLoaded,
    loadingProgression: prizePoolLoadingProgression,
    // unload: prizePoolUnload,
    // requestFullscreen: prizePoolRequestFullScreen,
    addEventListener: prizePoolAddEventListener,
    removeEventListener: prizePoolRemoveEventListener,
  } = useUnityContext({
    loaderUrl: "/Prizepool/Build/WebGL.loader.js",
    dataUrl: "/Prizepool/Build/WebGL.data",
    frameworkUrl: "/Prizepool/Build/WebGL.framework.js",
    codeUrl: "/Prizepool/Build/WebGL.wasm",
  });

  const {
    unityProvider: digitDreamsUnityProvider,
    isLoaded: digitDreamsLoaded,
    loadingProgression: digitDreamsLoadingProgression,
    // unload: digitDreamsUnload,
    // requestFullscreen: digitDreamsRequestFullScreen,
    addEventListener: digitDreamsAddEventListener,
    removeEventListener: digitDreamsRemoveEventListener,
  } = useUnityContext({
    loaderUrl: "/Didgetdreams/Build/WebGL.loader.js",
    dataUrl: "/Didgetdreams/Build/WebGL.data",
    frameworkUrl: "/Didgetdreams/Build/WebGL.framework.js",
    codeUrl: "/Didgetdreams/Build/WebGL.wasm",
  });

  const {
    unityProvider: slotMachineUnityProvider,
    isLoaded: slotMachineLoaded,
    loadingProgression: slotMachineLoadingProgression,
    // unload: slotMachineUnload,
    // requestFullscreen: slotMachineRequestFullScreen,
    addEventListener: slotMachineAddEventListener,
    removeEventListener: slotMachineRemoveEventListener,
  } = useUnityContext({
    loaderUrl: "/Slotmachine/Build/WebGL.loader.js",
    dataUrl: "/Slotmachine/Build/WebGL.data",
    frameworkUrl: "/Slotmachine/Build/WebGL.framework.js",
    codeUrl: "/Slotmachine/Build/WebGL.wasm",
  });

  // MARK: LOADER OF GAME
  // We'll round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  const blackJackLoadingPercentage = Math.round(
    blackJackLoadingProgression * 100
  );
  const baccaratLoadingPercentage = Math.round(
    baccaratLoadingProgression * 100
  );
  const prizePoolLoadingPercentage = Math.round(
    prizePoolLoadingProgression * 100
  );
  const digitDreamsLoadingPercentage = Math.round(
    digitDreamsLoadingProgression * 100
  );
  const slotMachineLoadingPercentage = Math.round(
    slotMachineLoadingProgression * 100
  );

  // MARK: EXIT EVENTS
  useEffect(() => {
    blackJackAddEventListener("ExitGame", handleBlackJackModalClose);
    return () => {
      blackJackRemoveEventListener("ExitGame", handleBlackJackModalClose);
    };
  });

  useEffect(() => {
    baccaratAddEventListener("ExitGame", handleBaccaratModalClose);
    return () => {
      baccaratRemoveEventListener("ExitGame", handleBaccaratModalClose);
    };
  });

  useEffect(() => {
    prizePoolAddEventListener("ExitGame", handlePrizePoolModalClose);
    return () => {
      prizePoolRemoveEventListener("ExitGame", handlePrizePoolModalClose);
    };
  });

  useEffect(() => {
    digitDreamsAddEventListener("ExitGame", handleDigitDreamsModalClose);
    return () => {
      digitDreamsRemoveEventListener("ExitGame", handleDigitDreamsModalClose);
    };
  });

  useEffect(() => {
    slotMachineAddEventListener("ExitGame", handleSlotMachineModalClose);
    return () => {
      slotMachineRemoveEventListener("ExitGame", handleSlotMachineModalClose);
    };
  });

  // MARK: CLOSE MODALS
  const handleBlackJackModalClose = async () => {
    setBlackjackModal(false);
  };
  const handleBaccaratModalClose = async () => {
    setBaccaratModal(false);
  };
  const handlePrizePoolModalClose = async () => {
    setPrizePoolModal(false);
  };
  const handleDigitDreamsModalClose = async () => {
    setDigitDreamsModal(false);
  };
  const handleSlotMachineModalClose = async () => {
    setSlotMachineModal(false);
  };

  return (
    <div className={styles.Dashboard}>
      {
        // MARK: BLACK JACK MODAL
      }

      {blackjackModal ? (
        <ModalContainer handleClose={handleBlackJackModalClose}>
          <div className={styles.modalContainer}>
            {blackJackLoaded === false && (
              <LandscapeOnly orientation={orientation}>
                <div
                  className={`d-flex flex-column align-items-center justify-content-center h-100 w-100`}
                >
                  <p className="m-0">
                    Loading... ({blackJackLoadingPercentage}%)
                  </p>
                </div>
              </LandscapeOnly>
            )}
            {
              <>
                <Unity
                  unityProvider={blackJackUnityProvider}
                  devicePixelRatio={window.devicePixelRatio}
                  style={{
                    visibility: blackJackLoaded ? "visible" : "hidden",
                    display: orientation === "landscape" ? "block" : "none",
                    height: "100%",
                    width: "100%",
                  }}
                />
                <MessageScreen orientation={orientation} />
              </>
            }
          </div>
        </ModalContainer>
      ) : (
        ""
      )}

      {
        // MARK: BACCARAT MODAL
      }
      {baccaratModal ? (
        <ModalContainer handleClose={handleBaccaratModalClose}>
          <div className={styles.modalContainer}>
            {baccaratLoaded === false && (
              <LandscapeOnly orientation={orientation}>
                <div
                  className={`d-flex flex-column align-items-center justify-content-center h-100 w-100`}
                >
                  <p className="m-0">
                    Loading... ({baccaratLoadingPercentage}%)
                  </p>
                </div>
              </LandscapeOnly>
            )}
            {
              <>
                <Unity
                  unityProvider={baccaratUnityProvider}
                  devicePixelRatio={window.devicePixelRatio}
                  style={{
                    visibility: baccaratLoaded ? "visible" : "hidden",
                    display: orientation === "landscape" ? "block" : "none",
                    height: "100%",
                    width: "100%",
                  }}
                />

                <MessageScreen orientation={orientation} />
              </>
            }
          </div>
        </ModalContainer>
      ) : (
        ""
      )}
      {
        // MARK: PRIZE POOL MODAL
      }
      {prizePoolModal ? (
        <ModalContainer handleClose={handlePrizePoolModalClose}>
          <div className={styles.modalContainer}>
            {prizePoolLoaded === false && (
              <LandscapeOnly orientation={orientation}>
                <div
                  className={`d-flex flex-column align-items-center justify-content-center h-100 w-100`}
                >
                  <p className="m-0">
                    Loading... ({prizePoolLoadingPercentage}%)
                  </p>
                </div>
              </LandscapeOnly>
            )}
            {
              <>
                <Unity
                  unityProvider={prizePoolUnityProvider}
                  devicePixelRatio={window.devicePixelRatio}
                  style={{
                    visibility: prizePoolLoaded ? "visible" : "hidden",
                    display: orientation === "landscape" ? "block" : "none",
                    height: "100%",
                    width: "100%",
                  }}
                />
                <MessageScreen orientation={orientation} />
              </>
            }
          </div>
        </ModalContainer>
      ) : (
        ""
      )}
      {
        // MARK: DIGIT DREAMS MODAL
      }
      {digitDreamsModal ? (
        <ModalContainer handleClose={handleDigitDreamsModalClose}>
          <div className={styles.modalContainer}>
            {digitDreamsLoaded === false && (
              <LandscapeOnly orientation={orientation}>
                <div
                  className={`d-flex flex-column align-items-center justify-content-center h-100 w-100`}
                >
                  <p className="m-0">
                    Loading... ({digitDreamsLoadingPercentage}%)
                  </p>
                </div>
              </LandscapeOnly>
            )}
            {
              <>
                <Unity
                  unityProvider={digitDreamsUnityProvider}
                  devicePixelRatio={window.devicePixelRatio}
                  style={{
                    visibility: digitDreamsLoaded ? "visible" : "hidden",
                    display: orientation === "landscape" ? "block" : "none",
                    height: "100%",
                    width: "100%",
                  }}
                />
                <MessageScreen orientation={orientation} />
              </>
            }
          </div>
        </ModalContainer>
      ) : (
        ""
      )}
      {
        // MARK: SLOT MACHINE MODAL
      }
      {slotMachineModal ? (
        <ModalContainer handleClose={handleSlotMachineModalClose}>
          <div className={styles.modalContainer}>
            {slotMachineLoaded === false && (
              <LandscapeOnly orientation={orientation}>
                <div
                  className={`d-flex flex-column align-items-center justify-content-center h-100 w-100`}
                >
                  <p className="m-0">
                    Loading... ({slotMachineLoadingPercentage}%)
                  </p>
                </div>
              </LandscapeOnly>
            )}
            {
              <>
                <Unity
                  unityProvider={slotMachineUnityProvider}
                  devicePixelRatio={window.devicePixelRatio}
                  style={{
                    visibility: slotMachineLoaded ? "visible" : "hidden",
                    display: orientation === "landscape" ? "block" : "none",
                    height: "100%",
                    width: "100%",
                  }}
                />
                <MessageScreen orientation={orientation} />
              </>
            }
          </div>
        </ModalContainer>
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle classes="akiraFont" text={"Games"} />
          <Description
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
            classes="mt-1 fs-8"
          />
        </div>
      </div>

      <Row className={` mt-2 mb-2`} gutter={[0, 24]}>
        {gameList.map((game, index) => (
          <Col className={`mt-2 mb-2`} lg={4} md={6} sm={6} key={game.id}>
            {game.active ? (
              <div
                key={game.gameName}
                className={styles.gameCard}
                // onClick={() => alert(game.gameName)}
                onClick={() => openGame(index)}
              >
                <img
                  // src={"/images/game_image_1.png"}
                  src={`/images/${game.gameImage}`}
                  alt="Card Banner"
                  className={styles.gameImage}
                />

                <div className={styles.shadowContainer}></div>

                <div className={styles.content}>
                  <div className="fs-4 fw-bold"> {game.gameName} </div>
                  <div style={{ fontSize: "small" }}>
                    {game.gameDescription}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.comingSoonCard} key={game.id}>
                <div className={styles.comingSoonContent}>
                  <div className=" bigeroverFont"> Comming Soon </div>
                </div>
              </div>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Dashboard;

const MessageScreen = ({ orientation }) => {
  return (
    orientation === "portrait" && (
      <div className="h-75 d-flex justify-content-center align-items-center">
        <h1 className="text-white w-full text-center">
          Landscape mode supported for an enhanced gaming experience.
        </h1>
      </div>
    )
  );
};

const LandscapeOnly = ({ orientation, children }) => {
  return orientation === "landscape" ? children : null;
};
