import React from 'react';

import styles from "./styles/AccountLayout.module.scss"

import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";


import { ShadowContainer } from '../CustomComponents';

export default function AccountLayout({
    hasBackButton = false,
    handleBackButton = () => { },
    children,
    registeredPage
}) {
    return (
        <div className={styles.AccountLayout} style={{ backgroundImage: `url(/images/login_bg.jpg)` }}>
            <ShadowContainer />
            <Container className={styles.sectionContainer}>

                <Row className={styles.row}>
                    <Col
                        md={registeredPage ? 8 : 6}
                        className={styles.formContainer}
                    >

                        <div className={styles.form} style={{ maxWidth: registeredPage ? '' : '420px' }}>
                            {hasBackButton ? <div className={styles.backButtonContainer} onClick={handleBackButton}>
                                <IoIosArrowBack />
                            </div> : ""
                            }
                            {children}
                        </div>
                    </Col>
                    <Col
                        md={registeredPage ? 4 : 6}
                        className={`${styles.mediaContainer}`}
                    >
                        <img src='/images/front_image.png' alt='Logo Assets' className={styles.image} />
                    </Col>

                </Row>
            </Container>

        </div>
    )
}