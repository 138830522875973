import React from "react";

import styles from "./styles/Navbar.module.scss";
import { IoIosNotificationsOutline } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { LuSettings2 } from "react-icons/lu";



export default function Navbar({ isSearchBar = true, isSettingButton = false, handleToggle, handleNotificationModalOpen, handleFilterModalOpen }) {


    return (
        <div className={styles.Navbar}>
            <div className={styles.titleContainer}>
                <span onClick={handleToggle} className={styles.toggleButton}><GiHamburgerMenu /></span>
                {/* <h3 className={styles.title}>{title}</h3> */}
                {isSearchBar ?
                    <div className={styles.search}>
                        <div className={styles.searchButton}>
                            <AiOutlineSearch color="#8F8F8F" />
                        </div>
                        <input
                            type="text"
                            placeholder="Search Games"
                            className="bigeroverFont p-2 ms-1"
                        // value={search}
                        // onChange={(e) => dispatch(setValue(e.target.value))}
                        />
                    </div>
                    : ''}
            </div>
            {isSettingButton ?
                <div className={styles.notificationButton} onClick={handleFilterModalOpen} >
                    <LuSettings2 />
                </div>
                : ''}
            <div className={styles.notificationButton} onClick={handleNotificationModalOpen}>
                <IoIosNotificationsOutline />
            </div>

        </div >
    )
}