import React from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  Description,
  Input,
  SubTitle,
  Title,
  UnorderedList,
} from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { Form, InputGroup } from "react-bootstrap";
import { PiKey } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useChangeUserPasswordMutation } from "../../Data/services/userAuthApi";
import { removeToken } from "../../Data/services/localStorageService";
import { setAuthInfo } from "../../Data/Features/authSlice";

export default function SetPassword({ setPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });
  // Watch the password field value to compare with confirm password
  const new_password = watch("new_password");

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.OTP);
  };

  // const handleSubmit = () => {
  //     setPage(ACCOUNT_ENUM.LOGIN)
  // }

  const [changePassword, { isError, isLoading, isSuccess }] =
    useChangeUserPasswordMutation();

  const handleChangePassword = async (data) => {
    console.log(data);

    try {
      const response = await changePassword(data);

      console.log(response);

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.errors;

        Toast(`${message}`, "error");
        // console.log(message)
      }

      if (!response.error && response.data.statusCode === 200) {
        dispatch(
          setAuthInfo({
            token: "",
            email: "",
            name: "",
            isLoggedIn: false,
            keepLoggedIn: "",
          })
        );

        removeToken();
        SuccessToast(response?.data?.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      removeToken();
      Toast("Something went wrong.", "error");
    }
  };

  return (
    <AccountLayout
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.new_password?.message
          ? Toast(errors?.new_password?.message, "error")
          : errors?.confirmed_password?.message
          ? Toast(errors?.confirmed_password?.message, "error")
          : null
        : ""}

      <div className="d-flex justify-content-center">
        <img
          src="/images/logo.png"
          alt="Logo Assets"
          style={{ width: "30%" }}
        />
      </div>
      <br></br>
      <SubTitle text="Set New Password" classes="mb-1 akiraFont text-center" />

      <p
        style={{
          letterSpacing: "12px",
          paddingLeft: "13px",
          wordSpacing: "-10px",
        }}
      >
        OF YOUR <span style={{ color: "#D43737" }}>ACCOUNT</span>
      </p>

      <Form onSubmit={handleSubmit(handleChangePassword)}>
        <Controller
          control={control}
          name="new_password"
          rules={{
            required: "New Password is required.",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long.",
            },
            pattern: {
              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
              message:
                "Password must be 6-16 characters long and include at least one number and one special character.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder="Set New Password"
                classes="mt-2 mb-1"
                type="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <div className="input-before-icon">
                <PiKey />
              </div>
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="confirmed_password"
          rules={{
            required: "Confirm Password is required.",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long.",
            },
            pattern: {
              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
              message:
                "Password must be 6-16 characters long and include at least one number and one special character.",
            },
            validate: (value) =>
              value === new_password || "Confirm Password do not match.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder="Confirm  Password"
                classes="mt-2 mb-1"
                type="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <div className="input-before-icon">
                <PiKey />
              </div>
            </InputGroup>
          )}
        />

        <br></br>
        <br></br>

        <Button
          text="Change Password"
          classes="mt-4 mb-2"
          // onClick={handleSubmit}
          type="submit"
        />
      </Form>
    </AccountLayout>
  );
}
