import React from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  Description,
  Input,
  SubTitle,
  Title,
} from "../../Components/CustomComponents";
import { ACCOUNT_ENUM } from "./Route";
import { Form, InputGroup } from "react-bootstrap";
import { CiUser } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useSendOtpMutation } from "../../Data/services/userAuthApi";

export default function ForgotPassword({ setPage, setEmail }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues:{
    //     email: '',
    //     password : '',
    // }
  });

  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleSignUpClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };

  const handleOtpPage = () => {
    setPage(ACCOUNT_ENUM.OTP);
  };

  const [sendOtp, { isError, isLoading, isSuccess }] = useSendOtpMutation();

  const handleForgotEmail = async (data) => {
    // console.log(data)

    try {
      const response = await sendOtp(data);
      // console.log(response)

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.errors;
        Toast(`${message}`, "error");
        // console.log(message)
      }

      if (!response.error && response.data.statusCode === 200) {
        SuccessToast(response?.data?.message);
        setEmail(data.email);
        handleOtpPage();
      }
    } catch (error) {
      console.log(error);
      Toast("Something went wrong.", "error");
    }
  };

  return (
    <AccountLayout
      hasBackButton={false}
      handleBackButton={handleBackButtonClick}
    >
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : null
        : ""}

      <div className="d-flex justify-content-center">
        <img
          src="/images/logo.png"
          alt="Logo Assets"
          style={{ width: "30%" }}
        />
      </div>
      <br></br>
      <SubTitle text="Forgot Password" classes="mb-1 akiraFont" />

      <p
        style={{
          letterSpacing: "12px",
          paddingLeft: "13px",
          wordSpacing: "-10px",
        }}
      >
        OF YOUR <span style={{ color: "#D43737" }}>ACCOUNT</span>
      </p>

      <Form onSubmit={handleSubmit(handleForgotEmail)}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email is required.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email address.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder="Email Address"
                type="email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />

              <div className="input-before-icon">
                <CiUser />
              </div>
            </InputGroup>
          )}
        />

        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Button
          text="Forgot Password"
          classes="mt-3 mb-2"
          // onClick={handleSubmit}
          type="submit"
        />
      </Form>

      <div className="d-flex justify-content-center flex-direction-row mt-5">
        <Description text="Haven’t Account?" classes="fs-7" />
        <Description
          text="Create a new account!"
          classes="link fs-7 ps-1"
          onClick={handleSignUpClick}
        />
      </div>
    </AccountLayout>
  );
}
