import React, { useState } from "react";
import styles from "./styles/index.module.css";
import {
  Button,
  Description,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { Col, Row } from "react-bootstrap";

// icon
import { BiSolidEdit } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { MdEdit } from "react-icons/md";

import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import { ROUTES } from "./Route";
import { Navigate, useNavigate } from "react-router-dom";

const Profile = ({ setPage }) => {
  const [isEditProfile, setIsEditProfile] = useState(false);
  const navigate = useNavigate();

  const [text, setText] = useState("Abram Westervelt"); // Initial text state
  const [editMode, setEditMode] = useState(false); // Edit mode state

  const handleEdit = () => {
    setEditMode(true); // Enable edit mode
  };

  const handleSave = () => {
    // Save the edited text or perform any necessary actions
    setEditMode(false); // Disable edit mode
  };

  const handleChange = (event) => {
    setText(event.target.value); // Update text as the user types
  };

  const [selectedCountry, setSelectedCountry] = useState("Canada");
  const countryList = ["UK", "USA", "Canada", "Turkey", "Spain"];

  const avatarList = [
    { src: "images/avatars/avatar_1.png", id: "1" },
    { src: "images/avatars/avatar_2.png", id: "2" },
    { src: "images/avatars/avatar_3.png", id: "3" },
    { src: "images/avatars/avatar_4.png", id: "4" },
    { src: "images/avatars/avatar_5.png", id: "5" },
    { src: "images/avatars/avatar_6.png", id: "6" },
    { src: "images/avatars/avatar_7.png", id: "7" },
    { src: "images/avatars/avatar_8.png", id: "8" },
    { src: "images/avatars/avatar_9.png", id: "9" },
    { src: "images/avatars/avatar_10.png", id: "10" },
  ];

  const handleCountryChange = (value) => setSelectedCountry(value);
  const handleEditProfile = (value) => setIsEditProfile(value);

  const handleSubmit = () => {
    handleSave();
    handleEditProfile(false);
  };
  const handleSavePassword = () => {
    handleEditProfile(false);
  };

  const gotoCurrencyScreen = () => {
    // setPage(ROUTES.CURRENCY);
    navigate('/' + ROUTES.CURRENCY);

    // alert("Goto Currency Screen");
  };

  return (
    <div className={styles.Profile}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle classes="akiraFont" text={"Profile"} />
          <Description
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
            classes="mt-1 fs-8"
          />
        </div>
      </div>

      <Row className={` w-100 mt-2 mb-2`}>
        <Col className={`mt-2 mb-2`} md={6} sm={12}>
          <div className={`${styles.userContainer} mt-4 mb-3`}>
            <div className="d-flex flex-column justify-content-around align-items-center">
              <img
                src="/images/profile.png"
                alt="User Profile"
                className={styles.image}
              />

              <div
                className={styles.uploadBtn}
                onClick={() => alert("Upload a Photo")}
              >
                Upload Photo
              </div>
            </div>

            <div className="d-flex flex-column justify-content-around align-items-center">
              <div className={styles.nameContainer}>
                <div className={styles.userName}>
                  {editMode ? (
                    <Input
                      type="text"
                      classes="mb-2 fw-bold gilroyFont"
                      value={text}
                      onChange={handleChange}
                    />
                  ) : (
                    <div>{text}</div>
                  )}
                </div>
                {editMode ? (
                  ""
                ) : (
                  <BiSolidEdit color="white" size={20} onClick={handleEdit} />
                )}
              </div>

              <div className={styles.showCoins}>
                <div className={`${styles.dc} bigeroverFont`}>DC</div>

                <div className={` d-flex flex-column text-white bigeroverFont`}>
                  <p className="m-0 fs-1" style={{ lineHeight: 1 }}>
                    {" "}
                    500{" "}
                  </p>
                  <p className="m-0"> Dubb Coins </p>
                </div>

                <div className={styles.plusIcons} onClick={gotoCurrencyScreen}>
                  <FiPlus color="white" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="d-flex align-items-center mb-2">
              <p className="fs-5 fw-bold m-0 pe-2">Profile Information</p>
              <MdEdit color="#F1C256" onClick={() => handleEditProfile(true)} />
            </div>

            <p className="fw-bold m-0 mb-1 " style={{ color: "#F1C256" }}>
              Email Address
            </p>

            <Input
              placeholder="Abram@Example.com"
              type="email"
              classes="mb-2 fw-bold gilroyFont"
              // fontClass="gilroyFont"
              disabled={isEditProfile ? false : true}
            />

            <p className=" fw-bold m-0 mb-1 " style={{ color: "#F1C256" }}>
              Country
            </p>

            <SelectFieldComponent
              firstOption={
                selectedCountry ? selectedCountry : "Select Your Country"
              }
              optionsList={countryList}
              onSelectChange={handleCountryChange}
              disabled={isEditProfile ? false : true}
              className={styles.select}
            />

            <Button
              text="Save Changes"
              classes="mt-3 mb-2 bg p-3"
              onClick={handleSubmit}
            />
          </div>
        </Col>
        <Col className={`mt-2 mb-2`} md={6} sm={12}>
          <p className="fs-5 fw-bold m-0 pe-2">Avatars</p>

          <Row className={` w-100 mt-2 mb-2`}>
            {avatarList.map((avatar) => (
              <Col
                className={`mt-2 mb-2 ${styles.avatarCol}`}
                xs={6}
                sm={4}
                md={4}
                lg={3}
              >
                <img
                  src={avatar.src}
                  alt={avatar.id}
                  className={styles.avatarImg}
                />
              </Col>
            ))}
          </Row>

          <div>
            <div className="d-flex align-items-center mb-2">
              <p className="fs-5 fw-bold m-0 pe-2">Change Pasword</p>
            </div>

            <p className=" fw-bold m-0 mb-1 " style={{ color: "#F1C256" }}>
              Old Password
            </p>
            <Input
              placeholder="*******"
              type="password"
              classes="mb-2"
              disabled={isEditProfile ? false : true}
            />

            <p className=" fw-bold m-0 mb-1" style={{ color: "#F1C256" }}>
              New Password
            </p>
            <Input
              placeholder="*******"
              type="password"
              classes="mb-2"
              disabled={isEditProfile ? false : true}
            />

            <p className=" fw-bold m-0 mb-1" style={{ color: "#F1C256" }}>
              Confirm Password
            </p>
            <Input
              placeholder="*******"
              type="password"
              classes="mb-2"
              disabled={isEditProfile ? false : true}
            />

            <Button
              text="Change Password"
              classes="mt-3 mb-2 bg p-3"
              onClick={handleSavePassword}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
