import React, { useEffect, useState } from 'react';
import styles from './styles/index.module.css';

import { Button, CardTitle, Description, Input, SubTitle } from '../../Components/CustomComponents';
import { Col, Container, Form, Row } from 'react-bootstrap';
// import { WEB_ROUTES } from '../../constants';
import { PAYMENT_TYPE } from './PaymentType';

// icon
import { BiSolidEdit } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import CustomRadioButton from '../../Components/CustomComponents/CustomRadioButton';



const Currency = ({ setPage }) => {
    const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.DEBIT_CARD);

    const handleRadioChange = (value) => {
        setPaymentType(value); // Update the state with the selected value
    };

    // useEffect(() => {
    //     console.log(paymentType)

    //     return () => {

    //     }
    // }, [paymentType])


    return (
        <div className={styles.Currency}>

            <div
                className={styles.headerContainer}
            >
                <div className={styles.titleContainer}>

                    <SubTitle
                        classes='akiraFont'
                        text={'Currency'}
                    />
                    <Description
                        text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                        classes='mt-1 fs-8'
                    />

                </div>

            </div>

            <Row className='my-3' >
                <Col sm={12} md={5}>

                    <div className={styles.showCoins}>

                        <div className={`${styles.dc} bigeroverFont`}>DC</div>

                        <div className={` d-flex flex-column text-white bigeroverFont p-1`}>
                            <p className='m-0 fs-1' style={{ lineHeight: 1 }}> 500 </p>
                            <p className='m-0' > Dubb Coins </p>
                        </div>


                        <div className={styles.plusIcons} >
                            <FiPlus color='white' />
                        </div>

                    </div>
                </Col>
                <Col sm={12} md={5}>
                    <div className={styles.connectCoinBox}>
                        <p className='m-0' > Connect BitCoin Wallet </p>

                    </div>
                </Col>
            </Row>

            <SubTitle
                classes='akiraFont fs-5 mb-3 mt-3'
                text={'Purchase Dub Coins'}
            />

            <SubTitle
                classes='fw-bold fs-5'
                text={'Enter the Coin amount'}
            />
            <Description
                text={'1 Dub Coin equals to 1 USD'}
                classes='mt-1 fs-8 mb-4'
            />

            <div className='my-3 d-flex flex-row' >


                <div className='px-3 py-2 rounded-4 text-center d-flex align-items-center justify-content-center' style={{ backgroundColor: '#202127' }}>
                    <p className='m-0'> 1500 Dub Coins </p>

                </div>

                <div className='p-3 text-center' >
                    <p className='m-0'>=</p>
                </div>

                <div className='px-4 py-2 rounded-4 text-center' style={{ backgroundColor: '#202127' }}>
                    <div className='flex-column'>
                        <p className='m-0 fs-8' style={{ color: '#F1C256' }}>Total Amount</p>
                        {paymentType === PAYMENT_TYPE.DEBIT_CARD ? <p className='m-0'> $1500 USD </p> : ''}
                        {paymentType === PAYMENT_TYPE.BITCOIN ? <p className='m-0'> 0.15 BTC </p> : ''}
                    </div>
                </div>

            </div>

            <Row>
                <Col sm={12} md={6} lg={5}>

                    <SubTitle
                        classes='fw-bold fs-5'
                        text={'Adding Coins via'}
                    />

                    <CustomRadioButton
                        cardNumber="****  ****  ****  4567"
                        cardType={PAYMENT_TYPE.DEBIT_CARD}
                        iconImage="images/master_card_icon.png"
                        onChange={handleRadioChange}
                        currentValue={paymentType}
                    />

                    <CustomRadioButton
                        cardNumber="#4512 4125 0344"
                        cardType={PAYMENT_TYPE.BITCOIN}
                        iconImage="images/bit_coin_icon.png"
                        onChange={handleRadioChange}
                        currentValue={paymentType}
                    />

                    <Button
                        text='Purchase Coins'
                        classes="mt-3 mb-2 bg p-3"
                        onClick={() => { alert('Purchase Coin ' + paymentType) }}
                    />

                </Col>
            </Row>


        </div >
    )
}

export default Currency
