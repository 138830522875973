import React, { useEffect, useState } from "react";
import styles from "./styles/index.module.css";
import './styles/index.css';

import {
  Button,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import { Col, Container, Form, Row } from "react-bootstrap";

// icon
// import { BiSolidEdit } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
// import { MdEdit } from "react-icons/md";
import CustomRadioButton from "../../Components/CustomComponents/CustomRadioButton";
import { MdDeleteOutline } from "react-icons/md";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";

// date
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const PaymentMethod = ({ setPage }) => {
  const [paymentType, setPaymentType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [expiryDate, setExpiryDate] = useState();

  const countryList = ["UK", "USA", "Canada", "Turkey", "Spain"];

  const handleCountryChange = (value) => setSelectedCountry(value);

  const handleRadioChange = (value) => {
    setPaymentType(value); // Update the state with the selected value
  };

  // useEffect(() => {
  //     console.log(paymentType)

  //     return () => {

  //     }
  // }, [paymentType])

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  return (
    <div className={styles.Payment}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle classes="akiraFont" text={"Payment Methods"} />
          <Description
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
            classes="mt-1 fs-8"
          />
        </div>
      </div>

      <Row className="my-5">
        <Col sm={12} md={3}>
          <div
            className={`d-flex w-100 flex-row justify-content-evenly rounded-4  mb-3 ${styles.paymentTypeCard} ${styles.active}`}
          >
            <img
              src="images/visa.png"
              alt="master card logo"
              width={50}
              className={`object-fit-contain`}
            />
            <img
              src="images/mastercard.png"
              alt="master card logo"
              width={50}
              className={`object-fit-contain`}
            />
          </div>

          <div
            className={`d-flex w-100 flex-row justify-content-evenly rounded-4 mb-3 ${styles.paymentTypeCard}`}
          >
            <img
              src="images/paypal.png"
              alt="master card logo"
              width={50}
              className={`object-fit-contain`}
            />
          </div>
        </Col>
        <Col sm={12} md={9}>
          <SubTitle classes="fw-bold fs-5" text={"Card Details"} />
          <Description
            classes="mt-1 fs-8 mb-4"
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
          />

          <Row>
            <Col sm={12} md={6}>
              <div
                className={`w-100 rounded-4 p-4 mb-3 ${styles.paymentCard} ${styles.active}`}
              >
                <p className={`mb-3 bigeroverFont fs-4 lc-1`}>
                  James Rhiel Madsen
                </p>
                <p className={`mb-2 bigeroverFont fs-5 lc-1`}>
                  **** **** **** 7865
                </p>
                <p className={`m-0`}>09/25</p>
              </div>
            </Col>
            <Col sm={12} md={3} className="align-content-center">
              <div
                className="px-3 py-2 my-3 rounded-4 text-center d-flex align-items-center justify-content-center"
                style={{ backgroundColor: "#202127", color: "white" }}
                onClick={() => {
                  alert("delete");
                }}
                role="button"
              >
                <span>
                  {" "}
                  <MdDeleteOutline /> Remove Card{" "}
                </span>
              </div>
            </Col>
          </Row>

          <SubTitle classes="fw-bold fs-5 mt-4" text={"Fill form Below"} />
          <Description
            classes="mt-1 fs-8 mb-4"
            text={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            }
          />
          <Row>
            <Col
              sm={12}
              md={9}
              className={`rounded-4 p-4 h-100 `}
              style={{ backgroundColor: "#202127" }}
            >
              <Row>
                <Col sm={12} md={6}>
                  <Form.Control
                    size="lg"
                    placeholder="Card Number"
                    type="text"
                    className={`mt-2 mb-2 gilroyFont  ${styles.Input} `}
                    onChange={() => {}}
                    // disabled={disabled}
                  />

                    {/* Ant-D datepicker */}
                    <DatePicker picker="month"  placeholder="Expiry Date" disabledDate={disabledDate} />

                  <Form.Control
                    size="lg"
                    placeholder="City"
                    type="text"
                    className={`mt-2 mb-2 gilroyFont  ${styles.Input} `}
                    onChange={() => {}}
                    // disabled={disabled}
                  />

                  <SelectFieldComponent
                    firstOption={selectedCountry ? selectedCountry : "Country"}
                    optionsList={countryList}
                    onSelectChange={handleCountryChange}
                    className={`${styles.select}`}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <Form.Control
                    size="lg"
                    placeholder="Name on Card"
                    type="text"
                    className={`mt-2 mb-2 gilroyFont  ${styles.Input} `}
                    onChange={() => {}}
                    // disabled={disabled}
                  />
                  <Form.Control
                    size="lg"
                    placeholder="CVV"
                    type="text"
                    className={`mt-2 mb-2 gilroyFont  ${styles.Input} `}
                    onChange={() => {}}
                    // disabled={disabled}
                  />
                  <Form.Control
                    size="lg"
                    placeholder="Postcode"
                    type="text"
                    className={`mt-2 mb-2 gilroyFont  ${styles.Input} `}
                    onChange={() => {}}
                    // disabled={disabled}
                  />
                  <Button
                    text="Add Card"
                    classes="mt-2 mb-2 p-4 bg"
                    // onClick={handleSubmit}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentMethod;
