import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./customRadioButton.module.css";

const CustomRadioButton = ({
  cardType,
  cardNumber,
  iconImage,
  onChange,
  currentValue,
}) => {
  return (
    <div>
      <label class={styles.container} >
        <Row className="h-100">
          <Col className="col-2 align-content-center">
            {/* <img src={iconImage} width={50} alt="icon_image" /> */}
            <img src={iconImage} width={'100%'} alt="icon_image" />
          </Col>
          <Col className={` align-content-center ${styles.text}`}>
            <p className="m-2 pe-5 lc-1" >{cardType}</p>

            <p className="m-2 pe-5 lc-1" >{cardNumber}</p>
          </Col>
          <Col className="col-auto align-content-center">
            <input
              type="radio"
              name="radio"
              checked={currentValue === cardType}
              onChange={() => onChange(cardType)}
              id={cardType}
            />
            <span class= {styles.checkmark}></span>
          </Col>
        </Row>
      </label>
    </div>
  );
};

export default CustomRadioButton;
