import React, { useRef } from "react";
import styles from "./index.module.scss";
import { useDraggable } from "react-use-draggable-scroll";



export default function Tabs({ tabs = [], current = '', onChange = () => { }, align = 'space-between', itemStyle = {}, containerStyles = {} }) {
    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref,
        {
            applyRubberBandEffect: true, // activate rubber band effect
            decayRate: 0.96, // specify the decay rate
            safeDisplacement: 11, // specify the drag sensitivity
        }
    ); // Now we pass the reference to the useDraggable hook:


    return (
        <div className={styles.TabsContainer}>
            <div
                style={{ ...{ overflow: 'auto', width: '100%' }, ...containerStyles }}
                className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
                {...events}
                ref={ref} // add reference and events to the wrapping div
            >
                <div className={`${styles.tabs} ${styles[align]}`}>
                    {tabs.map((item, index) => {
                        return <div
                            style={itemStyle}
                            className={`${styles.tab} ${(current === item.value) ? styles.active : ''}`}
                            onClick={() => onChange(item.value)}
                            key={index}
                        >
                            {item?.icon ? <div className={styles.iconContainer}>
                                <span className={styles.icon}>{item.icon}</span>
                                <span className={styles.active_icon}>{item.active_icon}</span>
                            </div> : ""}
                            {/* <p className={styles.label}> {item.image} </p> */}

                            <div className={styles.shadowContainer}></div>

                            <img src={item.image} width={70} height={70} className={`rounded-4 object-fit-cover $`} alt="game image" />

                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}


