import React, { useEffect } from "react";
import { ACCOUNT_ENUM } from "./Route";

import { useDispatch, useSelector } from "react-redux";
import { updateLogin } from "../../Data/Features/DefaultReducer";
import { Form, InputGroup } from "react-bootstrap";

// Custom Component
import AccountLayout from "../../Components/Layouts/AccountLayout";
import {
  Button,
  Checbox,
  Description,
  Input,
  SubTitle,
  Title,
} from "../../Components/CustomComponents";

// Icons
import { CiUser } from "react-icons/ci";
import { PiKey } from "react-icons/pi";

import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import { setAuthInfo } from "../../Data/Features/authSlice";
import {
  storeRefreshToken,
  storeToken,
  storeTokenForSession,
} from "../../Data/services/localStorageService";
import { WEB_ROUTES } from "../../constants";

export default function Login({ setPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({});

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleSignUpClick = () => {
    setPage(ACCOUNT_ENUM.REGISTER);
  };

  const [loginUser, { isError, isLoading, isSuccess }] = useLoginUserMutation();

  const handleLogin = async (data) => {
    console.log("handle Login");
    console.log(data);

    try {
      const response = await loginUser(data);

      console.log(response);

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.errors;
        Toast(`${message}`, "error");
        // console.log(message)
      }

      if (!response.error && response.data.statusCode === 200) {
        // console.log(response)
        // console.log(response.data.data.token[0].api_token)
        // console.log(response.data.data.email)
        // console.log(response.data.data.full_name)

        const token = response.data.data.token[0].api_token;
        const refreshToken = response.data.data.refreshToken;
        // const email = response.data.data.email;
        // const name = response.data.data.full_name;

        if (data.keep_me_login) {
          console.log("token store in local");
          storeToken(token);
          storeRefreshToken(refreshToken);
        } else {
          console.log("token store in session");
          storeTokenForSession(token);
        }

        SuccessToast(response?.data?.message);
        navigate(WEB_ROUTES.DASHBOARD);
      }
    } catch (error) {
      console.log(error);
      Toast("Something went wrong.", "error");
    }
  };

  return (
    <AccountLayout>
      {errors
        ? errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : errors?.password?.message
          ? Toast(errors?.password?.message, "error")
          : null
        : ""}

      <div className="d-flex justify-content-center">
        <img
          src="/images/logo.png"
          alt="Logo Assets"
          style={{ width: "30%" }}
        />
      </div>
      <br></br>
      <SubTitle text="Authorization" classes="mb-1 akiraFont" />

      <p
        style={{
          letterSpacing: "12px",
          paddingLeft: "13px",
          wordSpacing: "-10px",
        }}
      >
        OF YOUR <span style={{ color: "#D43737" }}>ACCOUNT</span>
      </p>

      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email is required.",
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Enter a valid email address.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder="Email Address"
                type="email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />

              <div className="input-before-icon">
                <CiUser />
              </div>
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{
            required: "Password is required.",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters long.",
            },
            pattern: {
              value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
              message:
                "Password must be 6-16 characters long and include at least one number and one special character.",
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder="Password"
                type="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <div className="input-before-icon">
                <PiKey />
              </div>
            </InputGroup>
          )}
        />

        <div className="w-100 d-flex justify-content-between align-items-center">
          <Controller
            control={control}
            name="keep_me_login"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Checbox
                label="Keep me login"
                classes="mt-2 mb-2"
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />

          <Description
            classes="ms-auto cursor-pointer"
            text="Forgot Password ?"
            onClick={handleForgotPasswordClick}
          />
        </div>

        <Button
          text="Login"
          classes="mt-3 mb-2 bg"
          // onClick={handleSubmit}
          type="submit"
        />
      </Form>

      {/* <TextDivider text='Or continue with' classes="mt-4 mb-3" />
            <Description
                text="Continue with Google, Apple or Facebook, you agree to Prime Time Tournaments Terms and Conditions & Privacy Policy"
                classes='m-2 fs-8 text-center'
            /> */}
      <div className="d-flex justify-content-center flex-direction-row mt-5">
        <Description text="Haven’t Account?" classes="fs-7" />
        <Description
          text="Create a new account!"
          classes="link fs-7 ps-1"
          onClick={handleSignUpClick}
        />
      </div>
    </AccountLayout>
  );
}
