import React, { useState } from "react";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { Button, Checbox, Description, Input, Title, SubTitle } from "../../Components/CustomComponents";
import { Col, Form, Row } from "react-bootstrap";
import { ACCOUNT_ENUM } from "./Route";
import { useDispatch } from "react-redux";
import SelectFieldComponent from "../../Components/CustomComponents/SelectFieldComponent";
import filterStyles from "../../Components/Modals/styles/filter.module.css";
import { InputGroup } from "react-bootstrap";

import { CiUser } from "react-icons/ci";
import { PiKey } from "react-icons/pi";
import { AiOutlineMail } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useRegisterUserMutation } from "../../Data/services/userAuthApi";

export default function Register({ setPage }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        // defaultValues:{
        //     email: '',
        //     password : '',
        // }
    });

    // Watch the password field value to compare with confirm password
    const password = watch("password");

    const [selectedCountry, setSelectedCountry] = useState('');

    const countryList = ['UK', 'USA', 'Canada', 'Turkey', 'Spain'];

    const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);

    const handleCountryChange = (value) => setSelectedCountry(value);


    const [registerUser, { isError, isLoading, isSuccess }] = useRegisterUserMutation();

    const handleRegister = async (data) => {
        // console.log('handle Register')
        // console.log(data)

        try {

            const response = await registerUser(data);

            console.log(response)

            if (response.error && response.error.status === 400) {
                const message = await response.error.data.errors;
                Toast(`${message}`, "error");
                console.log(message)
            }


            if (!response.error && response.data.statusCode === 200) {
                // console.log(response.data.data)
                // Toast('You are registered successfully, Now Login with your credentials', "success");
                SuccessToast('You are registered successfully, Now Login with your credentials');
                handleLoginClick();
            }


        } catch (error) {
            console.log(error)
            Toast('Something went wrong.', "error");


        }

    }


    return (
        <>
            <AccountLayout registeredPage={false} >

                {
                    errors ?
                        errors?.full_name?.message ? Toast(errors?.full_name?.message, 'error') :
                            errors?.email?.message ? Toast(errors?.email?.message, 'error') :
                                errors?.password?.message ? Toast(errors?.password?.message, 'error') :
                                    errors?.confirm_password?.message ? Toast(errors?.confirm_password?.message, 'error') :
                                        errors?.country?.message ? Toast(errors?.country?.message, 'error') :
                                            errors?.agree?.message ? Toast(errors?.agree?.message, 'error') : null
                        : ''
                }

                <div className='d-flex justify-content-center' >
                    <img src='/images/logo.png' alt='Logo Assets' style={{ width: '30%' }} />
                </div>

                <br></br>

                <SubTitle text="Create" classes="mb-1 akiraFont" />

                <p style={{ letterSpacing: '12px', paddingLeft: '13px', wordSpacing: '-10px' }}>A New <span style={{ color: '#D43737' }}>ACCOUNT</span></p>

                <Form onSubmit={handleSubmit(handleRegister)}>


                    <Controller
                        control={control}
                        name="full_name"
                        rules={{
                            required: "Full Name is required."
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputGroup>
                                <Input
                                    placeholder="Full Name"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                                <div className="input-before-icon" >
                                    <CiUser />
                                </div>
                            </InputGroup>
                        )} />

                    <Controller
                        control={control}
                        name="email"
                        rules={{
                            required: "Email is required.",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Enter a valid email address.",
                            },
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputGroup>
                                <Input
                                    placeholder="Email Address"
                                    type="email"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                                <div className="input-before-icon" >
                                    <AiOutlineMail />
                                </div>
                            </InputGroup>
                        )} />

                    <Controller
                        control={control}
                        name="password"
                        rules={{
                            required: "Password is required.",
                            minLength: {
                                value: 6,
                                message: "Password must be at least 6 characters long.",
                            },
                            pattern: {
                                value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                                message: 'Password must be 6-16 characters long and include at least one number and one special character.',
                            },
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputGroup>
                                <Input
                                    classes="mt-2 mb-2"
                                    placeholder="Password"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                                <div className="input-before-icon" >
                                    <PiKey />
                                </div>
                            </InputGroup>
                        )} />

                    <Controller
                        control={control}
                        name="confirm_password"
                        rules={{
                            required: "Confirm Password is required.",
                            minLength: {
                                value: 6,
                                message: "Password must be at least 6 characters long.",
                            },
                            pattern: {
                                value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                                message: 'Password must be 6-16 characters long and include at least one number and one special character.',
                            },
                            validate: value => value === password || "Confirm Password do not match."
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputGroup>
                                <Input
                                    classes="mt-2 mb-2"
                                    placeholder="Confirm Password"
                                    type="text"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                                <div className="input-before-icon" >
                                    <PiKey />
                                </div>
                            </InputGroup>
                        )} />

                    <Controller
                        control={control}
                        name="country"
                        rules={{
                            required: "Country is required.",
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <SelectFieldComponent
                                firstOption={selectedCountry ? selectedCountry : 'Select Your Country'}
                                optionsList={countryList}
                                // onSelectChange={handleCountryChange}
                                onSelectChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="how_found_us"
                        rules={{
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Input
                                classes="mt-2 mb-2 p-4"
                                placeholder="How to found us"
                                type="text"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="agree"
                        rules={{
                            required: 'Must be agree with Condition and Privacy Policy.'
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (

                            <Checbox
                                label='Agree to Card Game Terms and Conditions & Privacy Policy'
                                classes="mt-2 mb-2"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                            />
                        )}
                    />

                    <Button
                        // onClick={handleSubmit}
                        text='Register'
                        classes="mt-4 mb-2"
                        type="submit"
                    />

                </Form>


                <div
                    className='d-flex justify-content-center flex-direction-row'
                >
                    <Description
                        text='Already a memeber?'
                        classes="fs-7"
                    />
                    <Description
                        text='Login'
                        classes="link fs-7 ps-1"
                        onClick={handleLoginClick}
                    />

                </div>

            </AccountLayout>
        </>
    );
}