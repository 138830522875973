import React, { useState } from "react";

import { ACCOUNT_ENUM } from "./Route";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";
import Register from "./Register";
import OTP from "./OTP";
import { getRefreshToken } from "../../Data/services/localStorageService";
import { useCheckRefreshTokenMutation } from "../../Data/services/userAuthApi";
import { useEffect } from "react";

export default function Account() {
  const [state, setState] = useState(ACCOUNT_ENUM.LOGIN);
  const [email, setEmail] = useState("");

  switch (state) {
    case ACCOUNT_ENUM.REGISTER:
      return <Register setPage={setState} />;
    case ACCOUNT_ENUM.LOGIN:
      return <Login setPage={setState} />;
    case ACCOUNT_ENUM.FORGOT_PASSWORD:
      return <ForgotPassword setPage={setState} setEmail={setEmail} />;
    case ACCOUNT_ENUM.OTP:
      return <OTP setPage={setState} email={email} />;
    case ACCOUNT_ENUM.SET_PASSWORD:
      return <SetPassword setPage={setState} />;
    default:
      return <Login />;
  }
}
