import React, { useState } from 'react';
import { ROUTES } from './Route';

import Instructions from './Instructions';


export default function INSTRUCTIONS() {
    const [page, setPage] = useState(ROUTES.INSTRUCTIONS);


    switch (page) {
        case ROUTES.INSTRUCTIONS:
            return <Instructions setPage={setPage} />

        default:
            return <Instructions setPage={setPage} />
    }


}

