import React, { useState } from "react";
// import { Tab, Nav } from 'react-bootstrap';
import Tabs from "../../Components/Tabs/Tabs";

import styles from "./styles/index.module.css";
import "./styles/index.css";

import {
    Description,
    SubTitle,
} from "../../Components/CustomComponents";
import { Col,  Row } from "react-bootstrap";
import TabContent from "./TabContent";
// import { WEB_ROUTES } from '../../constants';

const Instructions = ({ setPage }) => {
    const [key, setKey] = useState("BlackJack");

    const array = [
        {
            value: "BlackJack",
            image: "images/game_image_1.png",
        },
        {
            value: "Baccarat",
            image: "images/game_image_2.png",
        },
        {
            value: "Slot Machine",
            image: "images/game_image_3.png",
        },
        {
            value: "Prize Pool",
            image: "images/game_image_4.png",
        },
        {
            value: "Jackpot",
            image: "images/game_image_5.png",
        },
    ];

    const inlineItemStyles = {
        // background: "#222232",
        // margin: "5px",
        // padding: "10px 20px",
    };

    const tabContent = () => {
        const item = array.find(item => item.value === key);
        switch (key) {
            case "BlackJack":
                // return "This is Content of 1";
                return <TabContent name={item.value} />;
            case "Baccarat":
                return <TabContent name={item.value} />;
            case "Slot Machine":
                return <TabContent name={item.value} />;
            case "Prize Pool":
                return <TabContent name={item.value} />;
            case "Jackpot":
                return <TabContent name={item.value} />;

            default:
                return "This is Content of 0";
        }
    };

    return (
        <div className={styles.Instruction}>
            <div className={styles.headerContainer}>
                <div className={styles.titleContainer}>
                    <SubTitle classes="akiraFont" text={"Instructions"} />
                    <Description
                        text={
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                        }
                        classes="mt-1 fs-8"
                    />
                </div>
            </div>

            <Row>
                <Col className="p-2 col-auto">
                    <Tabs
                        tabs={array}
                        current={key}
                        onChange={setKey}
                        itemStyle={inlineItemStyles}
                        containerStyles={{ width: "fit-content" }}
                    />
                </Col>

                <Col className={`p-2 ${styles.content}`}>
                    {
                        tabContent()
                    }

                </Col>
            </Row>
        </div>
    );
};

export default Instructions;
